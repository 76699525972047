import Image from 'next/legacy/image';

const TrustedBySection = () => (
  <div className="align-center container mx-auto mb-12 flex flex-col gap-6 lg:mb-32">
    <div className="text-semibold text-center text-sm uppercase text-white">Trusted by</div>
    <div className="gap-7.5 mx-auto flex flex-wrap content-center items-center justify-center lg:max-w-6xl lg:gap-x-16 lg:gap-y-10">
      <div className="relative h-[32px] w-[180px] lg:h-[40px] lg:w-[250px]">
        <Image loading="lazy" src="/logos/materialize.svg" alt="Materialize" layout="fill" />
      </div>
      <div className="relative h-[24px] w-[120px] lg:h-[40px] lg:w-[150px]">
        <Image loading="lazy" src="/logos/oqton.svg" alt="Oqton" layout="fill" />
      </div>

      <div className="relative h-[35px] w-[147px] lg:h-[40px] lg:w-[130px]">
        <Image loading="lazy" src="/logos/weaviate.svg" alt="Weaviate" layout="fill" />
      </div>
      <div className="relative h-[35px] w-[147px] lg:h-[40px] lg:w-[100px]">
        <Image loading="lazy" src="/logos/fal.ai.svg" alt="Fal AI" layout="fill" />
      </div>
      <div className="relative h-[35px] w-[140px] lg:h-[40px] lg:w-[150px]">
        <Image loading="lazy" src="/logos/modal-logo.svg" alt="Modal" layout="fill" />
      </div>
      <div className="relative h-[35px] w-[40px] lg:h-[40px] lg:w-[40px]">
        <Image loading="lazy" src="/logos/s2.svg" alt="Modal" layout="fill" />
      </div>
    </div>
  </div>
);

export default TrustedBySection;
